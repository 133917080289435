import React from 'react';

const NotFound404Page = ({underConstruction}) => {


    document.title = underConstruction ? "Oops, page is under construction - UrgentCare.com" : "Page not found - UrgentCare.com";
    return (
        <>
        <div className="section py-5" style={{
            backgroundColor: "rgb(246, 248, 250)",
        }}>
            <div className="container">
                <div className="row justify-content-center text-center">
                    <div className="col-md-12  ">
                        <img src="/img/404.svg" alt='not found' style={{
                            width: "290px",
                        }}/>
                        <h1 className="pt-md-5 pb-md-3">{(underConstruction ?? false) ? "Page under construction" : "Page not found"}</h1>
                        <p className="pb-md-5">{(underConstruction ?? false) ? "Oops! Page is still under construction. Please, come back soon and try again." :
                                                                               "The page you requested could not be found."}</p>
                    </div>
                </div>
            </div>
            </div>


        </>
    );
}

export default NotFound404Page;