import React from 'react';

const Copyright = () => {
    return(
        <div className="copyright text-center">
            <div className="container">
                <div className="row">
                    <div className="col">
                        Copyright © 2024 Urgent Care
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Copyright;