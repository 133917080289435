import React from 'react';

function LearnMoreSection(){
    return(
        <div className="section" style={{backgroundColor:"rgba(246,248,250,2)"}}>
            <div className="container">

                <div className="row">
                

                      <a className="col-md-6 mt-4" href="https://urgentcare.carehub.wellsync.com/conditions" target='_blank' rel="noopener noreferrer">
                        <div className="home-box round-border-10 p-40" style={{backgroundColor:"rgba(255,255,255,0.7)"}}>
                            <img src="/img/ico2/Appointment.svg" alt="Book Your Doctor" className="mb-2 mb-md-5" height="145px" width="auto" style={{padding: "2px", margin: "0px auto", display: "block"}}/>
                            <h3 className="mb-0 text-center">Book Your Virtual Doctor's Appointment Today</h3>
                            <p className="lead-violet mb-3 text-center">Convenient, Affordable, Fast and Easy!</p>
                            <h4 className="text-center pb-3 border-bottom">UrgentCare.com Can Help With General & Acute Conditions</h4>
                            <ul className="mb-5 half pl-5 md:pl-0">
                            <li>Medication Refill</li>
                            <li>Acid Reflux</li>
                            <li>Nausea</li>
                            <li>Vomiting</li>
                            <li>Acne</li>
                            <li>Pink Eye</li>
                            <li>Cold Sores</li>
                            <li>Shingles</li>
                            </ul>
                            <ul className="mb-5 half md:border-left pl-5">
                            <li>Allergies</li>
                            <li>Urinary Tract Infections</li>
                            <li>Sinus Headache</li>
                            <li>Sinus Infection</li>
                            <li>Adult Cold/Flu</li>                 
                            <li>Birth Control</li>
                            <li>And More</li>
                            </ul>
                           <div className="home-box-footer">
                               <button className="learn-more btn green">Chat With A Provider Online</button>
                             </div>
                        </div>
                      </a>
                
                <a className="col-md-6 mt-4" href="/weight-loss-program?utm_source=urgentcare&utm_medium=organic&utm_campaign=homepage_widget" target='_blank' rel="noopener noreferrer">
                    <div className="home-box round-border-10 p-40" style={{backgroundColor:"rgba(255,255,255,0.7)"}}>
                        <img src="img/ico2/GLP-1b.svg" alt="Lose weight with GLP-1" className="mb-3 mb-md-5" height="145px" width="auto" style={{padding: "2px", margin: "0px auto", display: "block"}} />
                        <h3 className="mb-0 mt-0 text-center">Lose Weight With GLP&#x2011;1 Medications.</h3>
                        <p className="lead-violet mb-3 text-center">Start Your Journey Today!</p>
                        <p className="mb-5">Obesity is a disease that is often still associated with stigma, bias and misinformation. We are working to help you make informed decisions around obesity while making GLP-1 medications more accessible. Helping fight obesity one person at a time.</p>
                        <div className="home-box-footer"><button className="learn-more btn green"  href="/weight-loss-program?utm_source=urgentcare&utm_medium=organic&utm_campaign=homepage_widget">Learn More</button>
                        </div>
                    </div>
                </a>
                </div>
            </div>
        </div>

    )
        
    
}

export default LearnMoreSection;