const initialState = {
    userLocation: {
        lat: 15,
        lng: 121,
    },
    userLocationAddress: "",
    zipLocation: {
        lat: 16,
        lng: 122,
    },
    zipLocationAddress: "",
    zip: "",
    filter: {
        radius: 20,
        antibodyTest: false,
        covidTest: false,
        xray: false,
    },
    clinics: [],
    filteredClinics: [],

    zipBased: true,
    redirectedFromEmptyZIPBasedSearch: false,
    redirectedFromEmptyLocationBasedSearch: false,
    displayAddress: "Invalid address or location",
    resultsPrompt: "",
    loading: false,
}

export default initialState;