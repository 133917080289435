import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { CLAIM_REGEX } from '../Helpers';

const NavBar = () => {
  const currentPathName = window.location.href;
  console.log(currentPathName);
  console.log(CLAIM_REGEX.test(currentPathName));
  return (
    <nav className="navbar navbar-expand-md navbar-light top-nav">
      <Helmet>
        <title>
          UrgentCare.com: Find Your Local Urgent Care Center, Get Directions, Hours of Operation and
          COVID-19 Testing
        </title>
        <meta
          name="description"
          content="Find your local urgent care center from our nationwide database of more than 14,000 locations. Listings include COVID-19 Viral and Antibody Testing information, x-ray, accepted insurance carriers, and other services."
        />
        <meta name="keywords" content="urgent care, urgent care near me" />
      </Helmet>
      {CLAIM_REGEX.test(currentPathName) || /\/claim/.test(currentPathName) ? (
        <Link to="/?claim=True" className="navbar-brand">
          <img src="/img/urgent-care-logo.svg" alt="urgent-care" className="img-fluid" />
        </Link>
      ) : (
        <Link to="/" className="navbar-brand">
          <img src="/img/urgent-care-logo.svg" alt="urgent-care" className="img-fluid" />
        </Link>
      )}
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarCollapse"
        aria-controls="navbarCollapse"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarCollapse">
        <ul className="navbar-nav ml-auto align-items-center">
          <a href="/covered-conditions" className="nav-link left">
            <li className="nav-item nav-btn ">Covered Conditions</li>
          </a>

          <a href="/about" className="nav-link">
            <li className="nav-item nav-btn">About Us</li>
          </a>

          {/* {
                        // MOBILE TAG
                        isMobile && !CLAIM_REGEX.test(currentPathName) &&
                        <li className="nav-item">
                            <a href="/practice/"
                            className="btn-cta mx-3">Find Urgent Care
                            </a>
                        </li>
                    }
                     */}
          {
            // MOBILE TAG
            // isMobile &&
            !CLAIM_REGEX.test(currentPathName) && (
              <li className="nav-item">
                <a
                  id="glp-nav-bar"
                  href="/weight-loss-program"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn-cta mx-3 btn green"
                >
                  Lose Weight With GLP&#x2011;1's
                </a>
              </li>
            )
          }
        </ul>
      </div>
    </nav>
  );
};

export default NavBar;
