import React from 'react';
import { Link } from 'react-router-dom';
import { CLAIM_REGEX } from '../Helpers';

const NavBar = () => {
  const currentPathName = window.location.href;
  // console.log(currentPathName);
  // console.log(CLAIM_REGEX.test(currentPathName));
  return (
    <nav className="navbar navbar-expand-md navbar-light top-nav weightloss">
      {CLAIM_REGEX.test(currentPathName) || /\/claim/.test(currentPathName) ? (
        <Link to="/?claim=True" className="navbar-brand">
          <img src="/img/urgent-care-logo.svg" alt="urgent-care" className="img-fluid" />
        </Link>
      ) : (
        <Link to="/" className="navbar-brand">
          <img src="/img/urgent-care-logo.svg" alt="urgent-care" className="img-fluid" />
        </Link>
      )}
      {/* <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
                    aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button> */}
      <div className="collapse navbar-collapse" id="navbarCollapse">
        <ul className="navbar-nav ml-auto align-items-center">
          <a href="#faq" className="nav-link">
            <li className="nav-item nav-btn ">FAQ</li>
          </a>

          <a href="#start" className="nav-link last">
            <li className="nav-item nav-btn ">START YOUR JOURNEY</li>
          </a>
        </ul>
      </div>
    </nav>
  );
};

export default NavBar;
