import React from 'react';
import ResultBox from "./ResultBox";

class ResultsContainer extends React.Component {
    state = {
        page: 1,
        paginationPage: 1
    }

    componentDidMount = () => {
        this.setState({
            page: this.props.page,
            paginationPage: this.props.paginationPage
        })
    }

    render() {
        
        const results = this.props.clinics.map((clinic, index) => {
            return (
                <ResultBox city={`${clinic.city}, ${clinic.state}`}
                           key={clinic.practice_id}
                           ratings={5}
                           premier={false}
                           name={clinic.name}
                           id={clinic.practice_id}
                           index={index}
                           practice={clinic}
                           zipBased={this.props.zipBased}
                           zipLocation={this.props.zipLocationCoordinates}
                           location={
                               {
                                   lat: clinic.latitude,
                                   lng: clinic.longitude,
                               }
                           }
                           userLocation={this.props.userLocationCoordinates}
                           schedules={clinic.schedules}
                           isActiveClickHandler={this.props.isActiveClickHandler}
                           forClaiming={this.props.forClaiming}
                           // zipOnly={this.state.zipOnly}
                />
            );
        });

        let handleChangePageClick = (number) => {
            this.props.handleChangePageClick(number)
        } 

        let paginated = results;
        if(results.length > 5){
            const start = 6 * (this.props.page - 1);
            const end = start + 6;
            paginated = results.slice(start, end);
        }
        let pageNumbers = [];

        for (let i = 1; i <= Math.ceil(results.length / 6); i++) {
            pageNumbers.push(i);
        };
        let page = this.props.page;
        

        let pageDisplay = pageNumbers.map(function (number) {
            return (
            <li className={"btn pagination-item pagination-item-flex pd-pg0 "+ (number === page ? "active-item" : "") }
                key={number}
                onClick={function(){handleChangePageClick(number)}}
            >
                <span className="">{number}</span>
            </li>
            );
        });
        let paginationNumbers = [];
        for (let i = 1; i <= Math.ceil(pageNumbers.length / 7); i++) {
            paginationNumbers.push(i);
        };
    
        
    
        let paginatedPagination = pageDisplay;
        
        if (pageDisplay.length > 5) {
            const start = 5 * (this.props.paginationPage - 1);
            const end = start + 5;
            paginatedPagination = pageDisplay.slice(start, end);
        }
    
       

        return (
            <>
                <div className="row">
                    {paginated}
                </div>
                { results.length > 7 &&
                    <div className="react pager flexbox-container">
                        <button className="learn-more learn-bmore react prev-button flexbox-item prev-flex"
                            onClick={this.props.handlePreviousPageClick}
                            disabled={this.props.page <= 1}
                        ><i className="fa fa-chevron-left" aria-hidden="true"></i> Prev</button>

                        <div className="pagination-container react paginator w-50 flexbox-item rpagination-flex pd-pg0">

                            {pageDisplay.length > 7 &&
                                <button className="btn react prev-button pagination-arrow" disabled={this.props.paginationPage <= 1} onClick={this.props.handlePreviousPaginationClick}>
                                    <i className="fa fa-chevron-left" aria-hidden="true" ></i>
                                </button>}

                            {paginatedPagination}
                            {pageDisplay.length > 7 &&
                                <button
                                    className="btn react next-button pagination-arrow" disabled={this.props.paginationPage === Math.ceil(pageDisplay.length / 7)} onClick={this.props.handleNextPaginationClick}>
                                    <i className="fa fa-chevron-right" aria-hidden="true" ></i>
                                </button>}
                        </div>
                        <button className="learn-more learn-bmore react next-button flexbox-item next-flex"
                            onClick={this.props.handleNextPageClick}
                            disabled={this.props.page === Math.ceil(results.length / 6)}
                        >Next <i className="fa fa-chevron-right" aria-hidden="true"></i></button>
                    </div>
                }
            </>
        )
    }
}

export default ResultsContainer;