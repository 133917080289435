import React from 'react';

const StickyGeolocationMessage = (props) => {
    return (
        <div className='sticky-geolocation-message'>
            <p>{props.message}</p>
        </div>
    )
}

export default StickyGeolocationMessage;