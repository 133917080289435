import React from 'react';
// eslint-disable-next-line no-unused-vars
import {isMobile} from 'react-device-detect';
import {isZIPValid} from "../../Client";

class ZIPCodeWidget extends React.Component {
    state = {
        zip: this.props.initialZIP ? this.props.initialZIP : "",
        prompt: false,
    }

    handleZIPChange = (e) => {
        this.setState({
            zip: e.target.value,
            prompt: false,
            geolocationPrompt: false,
        });
    }

    handleZIPSubmitClick = async () => {
        // MOBILE TAG
        // if(!isMobile) {
        //     console.log("I am not mobile.")
        //     if(this.state.zip == "") {
        //         // Return immediately if empty
        //         this.setState({
        //             prompt: true,
        //             geolocationPrompt: this.props.isGeolocationDenied,
        //         });
        //         // this.props.onZIPSubmitClick(null);
        //     } else {
        //         const validZip = await isZIPValid(this.state.zip);
        //         if(!validZip) {
        //             this.setState({
        //                 prompt: true,
        //             });
        //         } else {
        //             this.props.onZIPSubmitClick(this.state.zip !== "" ? (this.state.zip).padStart(5, "0") : null);
        //         }
        //     }
        // } else {
        //     this.props.onZIPSubmitClick(this.state.zip !== "" ? (this.state.zip).padStart(5, "0") : null);
        // }

        console.log(this.state.zip === "");
        console.log(this.props.isGeolocationDenied);
        if (this.state.zip === "" && this.props.isGeolocationDenied) {
            // Return immediately if empty
            console.log('ZIP is empty and geolocation is denied.');
            this.setState({
                prompt: true,
                geolocationPrompt: this.props.isGeolocationDenied,
            });
            // this.props.onZIPSubmitClick(null);
        } else {
            if(this.state.zip === "") {
                return this.props.onZIPSubmitClick(this.state.zip !== "" ? (this.state.zip).padStart(5, "0") : null);
            }
            const validZip = await isZIPValid(this.state.zip);
            if (!validZip) {
                this.setState({
                    prompt: true,
                });
            } else {
                this.props.onZIPSubmitClick(this.state.zip !== "" ? (this.state.zip).padStart(5, "0") : null);
            }
        }
    }

    handleEnterSubmit = e => {
        if (e.key === "Enter") {
            this.handleZIPSubmitClick();
        }
    }

    render() {
        return (
            <div className="zip-box mb-4">
                {
                    this.state.geolocationPrompt &&
                    <p style={{
                        color: "red",
                        fontSize: "0.9rem",
                    }}>Geolocation is not enabled.</p>
                }
                {
                    this.state.prompt &&
                    <p style={{
                        color: "red",
                        fontSize: "0.9rem",
                    }}>Enter a valid ZIP code.</p>
                }

                <div className="float-left mr-3">
                    <h5>ZIP CODE</h5><input type="text" className="form-control mb-3 mb-lg-0 mb-xl-0 react zip-input"
                                            placeholder={this.state.zip !== "" ? this.props.zip : "*****"}
                                            required=""
                                            value={this.state.zip}
                                            onChange={this.handleZIPChange}
                                            onKeyDown={this.handleEnterSubmit}
                />
                </div>
                <button className="btn-cta" onClick={this.handleZIPSubmitClick}>Find My Location</button>
            </div>
        )
    }
}

export default ZIPCodeWidget;