import React from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import './App.css';
import AboutUs from "./components/aboutus/AboutUs";

import WeightLoss from "./components/weight-loss/WeightLoss";
import WeightLossOdt from "./components/weight-loss/WeightLossOdt";

import GetStartedSemaglutide from "./components/weight-loss/GetStartedSemaglutide";
import GetStartedTirzepatide from "./components/weight-loss/GetStartedTirzepatide";

import GetStartedSemaglutideODT from "./components/weight-loss/GetStartedSemaglutideODT";
import GetStartedTirzepatideODT from "./components/weight-loss/GetStartedTirzepatideODT";




import WeightLossForWomen from "./components/weight-loss-for-women/WeightLossForWomen";
import WeightLossForMen from "./components/weight-loss-for-men/WeightLossForMen";

import CoveredConditions from "./components/covered-conditions/CoveredConditions";
import MarketingCampaignPage from "./components/campaigns/MarketingCampaignPage";
import PreviewMarketingCampaignPage from "./components/campaigns/preview/PreviewMarketingCampaignPage";
import ClaimPage from "./components/claim/ClaimPage";
import Copyright from "./components/Copyright";
import Footer from './components/Footer';
import Footer2 from './components/Footer2';
// Pages
import HomePage from "./components/homepage/HomePage";
import ListingPage from "./components/listings/ListingPage";
// Common components
import NavBar from './components/Navbar';
import NavBar2 from './components/Navbar2';
import NotFound404Page from "./components/NotFound404Page";
import Partnership from "./components/partnership/Partnership";
import Privacy from "./components/Privacy";
import ResultsPage from "./components/results/ResultsPage";
import Subscription from "./components/subscription/Subscription";
import Terms from "./components/Terms";
// import InternalDisplay from "./components/InternalDisplay";
function NavBarSwitcher() {
  const location = useLocation();
  const isWeightLossPath = location.pathname.startsWith('/weight-loss-');

  return isWeightLossPath ? <NavBar2 /> : <NavBar />;
}

class App extends React.Component {

    render() {
        return (
            <div className="relative">
          {/* <InternalDisplay /> */}
          
          <NavBarSwitcher />
           
                <Switch>
                    <Route exact path='/practice/:slug' component={ListingPage} />
                    {/*<Route exact path='/:slug' component={ListingPage} />*/}
                    <Route exact path='/practice' render={(props) => <ResultsPage key={Date.now()} {...props}/>} />
                    <Route exact path='/covered-conditions' component={CoveredConditions} />
                    <Route exact path='/weight-loss-program' component={WeightLoss} />
                    
                    <Route exact path='/weight-loss-program-odt' component={WeightLossOdt} />
                    <Route exact path='/weight-loss-program/get-started-semaglutide' component={GetStartedSemaglutide} />
                    <Route exact path='/weight-loss-program/get-started-tirzepatide' component={GetStartedTirzepatide} />
                    <Route exact path='/weight-loss-program/get-started-semaglutide-odt' component={GetStartedSemaglutideODT} />
                    <Route exact path='/weight-loss-program/get-started-tirzepatide-odt' component={GetStartedTirzepatideODT} />
                    
                    <Route exact path='/weight-loss-for-women' component={WeightLossForWomen} />
                    <Route exact path='/weight-loss-for-men' component={WeightLossForMen} />
                    
                    
                    <Route exact path='/about' component={AboutUs} />
                    <Route exact path='/articles' component={() => (<NotFound404Page underConstruction={true} />)} />
                    <Route exact path='/partnership' component={Partnership} />
                    <Route exact path='/subscription' component={Subscription} />
                    <Route exact path='/404' component={() => (<NotFound404Page underConstruction={false}/>)} />
                    <Route exact path='/wip' component={() => (<NotFound404Page underConstruction={true} />)} />
                    <Route exact path='/' component={HomePage} />
                    <Route exact path='/privacy-policy' component={Privacy} />
                    <Route exact path='/terms-and-conditions' component={Terms} />
                    <Route exact path='/claim' render={(props) => <ClaimPage key={Date.now()} {...props}/>} />
                    <Route exact path='/campaigns/preview' component={PreviewMarketingCampaignPage} />
                    <Route exact path='/campaigns/:slug' component={MarketingCampaignPage} />


                    {/*Redirects*/}
                    <Redirect exact from={'/clinic/4516/winchester-urgent-care'} to={'/practice/U9715-winchester-urgent-care-winchester-va-22601'} />
                    <Redirect exact from={'/clinic/4499/nowcare-indian-river'} to={'/practice/U9678-bayview-physicians-nowcare-virginia-beach-va-23464'} />
                    <Redirect exact from={'/clinic/3870/sanford-acute-care-26th-amp-sycamore'} to={'/practice/U9611-sanford-health-walk-in-care-sioux-falls-sd-57103'} />
                    <Redirect exact from={'/clinic/3868/mcgreevy-dawley-farm'} to={'/practice/U9605-avera-walk-in-care-sioux-falls-sd-57110'} />
                    <Redirect exact from={'/clinic/2904/medcareplus'} to={'/practice/U9488-medcare-plus-ny-urgent-care-bronx-ny-10472'} />
                    <Redirect exact from={'/clinic/2741/seacoast-redicare'} to={'/practice/U9405-access-medical-associates-branchburg-nj-8876'} />
                    <Redirect exact from={'/clinic/2624/linc-care-south'} to={'/practice/U9398-medexpress-urgent-care-lincoln-ne-68516'} />
                    <Redirect exact from={'/clinic/2030/expresscare-elkton'} to={'/practice/U9216-expresscare-urgent-care-elkton-md-21921'} />
                    <Redirect exact from={'/clinic/2577/patients-first-urgent-care-washington'} to={'/practice/U9216-expresscare-urgent-care-elkton-md-21921'} />
                    <Redirect exact from={'/clinic/2019/expresscare-dundalk'} to={'/practice/U9209-expresscare-urgent-care-dundalk-md-21222'} />
                    <Redirect exact from={'/clinic/2064/expresscare-owings-mills'} to={'/practice/U9207-expresscare-urgent-care-owings-mills-md-21117'} />
                    <Redirect exact from={'/clinic/2093/expresscare-westminster'} to={'/practice/U9206-expresscare-urgent-care-westminster-md-21157'} />
                    <Redirect exact from={'/clinic/2113/compass-medical-east-bridgewater'} to={'/practice/U9187-steward-health-walk-in-care-east-bridgewater-ma-2333'} />
                    <Redirect exact from={'/clinic/1952/velocity-care-mansfield-road'} to={'/practice/U9186-christus-health-walk-in-care-shreveport-la-71118'} />
                    <Redirect exact from={'/clinic/1809/urgent-care-of-kentucky-frankfort'} to={'/practice/U9167-urgent-care-centers-of-kentucky-frankfort-ky-40601'} />
                    <Redirect exact from={'/clinic/1656/medcheck-noblesville'} to={'/practice/U9161-community-health-medcheck-noblesville-in-46060'} />
                    <Redirect exact from={'/clinic/56/greater-mobile-urgent-care-springhill'} to={'/practice/U8732-greater-mobile-urgent-care-mobile-al-36608'} />
                    <Redirect exact from={'/clinic/78/greater-mobile-urgent-care-semmes'} to={'/practice/U8731-greater-mobile-urgent-care-semmes-al-36575'} />
                    <Redirect exact from={'/clinic/2740/white-mountain-medical-center-walk-in-care'} to={'/practice/U8661-fmh-walk-in-care-sanbornville-nh-3872'} />
                    <Redirect exact from={'/clinic/1711/fhcsl-urgent-care-morningside'} to={'/practice/U8647-fhc-siouxland-walk-in-care-sioux-city-ia-51106'} />
                    <Redirect exact from={'/clinic/2691/sma-urgent-care-montecito'} to={'/practice/U8610-sma-walk-in-care-las-vegas-nv-89149'} />
                    <Redirect exact from={'/clinic/1183/quick-med-gray-highway'} to={'/practice/U8560-atrium-health-urgent-care-macon-ga-31211'} />
                    <Redirect exact from={'/clinic/786/saint-marys-walk-in-center-naugatuck'} to={'/practice/U8515-trinity-health-walk-in-care-naugatuck-ct-6770'} />
                    <Redirect exact from={'/clinic/827/saint-marys-walk-in-center-wolcott'} to={'/practice/U8514-trinity-health-walk-in-care-wolcott-ct-6716'} />
                    <Redirect exact from={'/clinic/782/milford-hospital-walk-in-center'} to={'/practice/U8504-yale-new-haven-health-urgent-care-milford-ct-6460'} />
                    <Redirect exact from={'/clinic/1485/osf-promptcare-route-91'} to={'/practice/U8496-osf-healthcare-walk-in-care-peoria-il-61615'} />
                    <Redirect exact from={'/clinic/1444/osf-promptcare-morton'} to={'/practice/U8495-osf-healthcare-walk-in-care-morton-il-61550'} />
                    <Redirect exact from={'/clinic/3647/northeastern-readycare'} to={'/practice/U8353-temple-readycare-philadelphia-pa-19134'} />
                    <Redirect exact from={'/clinic/2838/the-doctors-office-urgent-care-west-caldwell'} to={'/practice/U8145-excel-urgent-care-west-caldwell-nj-7006'} />
                    <Redirect exact from={'/clinic/4041/wellnow-springfield'} to={'/practice/U8129-urgent-team-springfield-tn-37172'} />
                    <Redirect exact from={'/clinic/2953/premier-care-of-great-neck'} to={'/practice/U7997-citymd-urgent-care-great-neck-ny-11021'} />
                    <Redirect exact from={'/clinic/4842/doctors-express-urgent-care'} to={'/practice/U7987-american-family-care-danbury-ct-6810'} />
                    <Redirect exact from={'/clinic/2773/medemerge'} to={'/practice/U7924-medemerge-green-brook-nj-8812'} />
                    <Redirect exact from={'/clinic/2967/five-star-urgent-care-jamestown'} to={'/practice/U7809-wellnow-urgent-care-jamestown-ny-14701'} />
                    <Redirect exact from={'/clinic/469/healthcare-partners-urgent-care-mission-hills'} to={'/practice/U7744-healthcare-partners-urgent-care-mission-hills-ca-91345'} />
                    <Redirect exact from={'/clinic/2405/the-urgency-room-woodbury'} to={'/practice/U7627-the-urgency-room-saint-paul-mn-55125'} />
                    <Redirect exact from={'/clinic/219/john-c-lincoln-urgent-care-north-phoenix'} to={'/practice/U7541-honorhealth-urgent-care-phoenix-az-85032'} />
                    <Redirect exact from={'/clinic/1634/iu-health-urgent-care-arnett-south'} to={'/practice/U7532-iu-health-urgent-care-lafayette-in-47909'} />
                    <Redirect exact from={'/clinic/671/emergicare-garden-of-the-gods'} to={'/practice/U732-qwikcaremd-urgent-care-colorado-springs-co-80907'} />
                    <Redirect exact from={'/clinic/51/urgent-medcare-meridianville'} to={'/practice/U7315-crh-healthcare-meridianville-al-35759'} />
                    <Redirect exact from={'/clinic/2516/the-urgency-room-liberty-mo'} to={'/practice/U7176-nextcare-urgent-care-liberty-mo-64068'} />
                    <Redirect exact from={'/clinic/2721/renown-urgent-care-los-altos-pkwy'} to={'/practice/U7123-renown-health-urgent-care-sparks-nv-89436'} />
                    <Redirect exact from={'/clinic/1319/dreyer-walk-in-care-batavia'} to={'/practice/U7002-advocate-aurora-walk-in-care-batavia-il-60510'} />
                    <Redirect exact from={'/clinic/2925/cayuga-convenient-care-cortland'} to={'/practice/U6833-cayuga-health-walk-in-care-cortland-ny-13045'} />
                    <Redirect exact from={'/clinic/3057/rapid-md'} to={'/practice/U6744-rapid-md-of-laurelton-laurelton-ny-11413'} />
                    <Redirect exact from={'/clinic/1330/edward-immediate-care-bolingbrook'} to={'/practice/U6598-edward-elmhurst-walk-in-care-bolingbrook-il-60440'} />
                    <Redirect exact from={'/clinic/1902/velocity-care-bossier-city'} to={'/practice/U6522-christus-health-walk-in-care-bossier-city-la-71111'} />
                    <Redirect exact from={'/clinic/4656/medexpress-morgantown'} to={'/practice/U5467-medexpress-urgent-care-morgantown-wv-26501'} />
                    <Redirect exact from={'/clinic/762/urgent-care-of-connecticut-brookfield'} to={'/practice/U5104-physicianone-urgent-care-brookfield-ct-6804'} />
                    <Redirect exact from={'/clinic/3295/dixie-urgent-care'} to={'/practice/U4896-carefirst-urgent-care-fairfield-oh-45014'} />
                    <Redirect exact from={'/clinic/2771/brunswick-urgent-care'} to={'/practice/U4665-brunswick-urgent-care-franklin-park-nj-8823'} />
                    <Redirect exact from={'/clinic/403/willow-urgent-care'} to={'/practice/U4543-willow-urgent-care-fresno-ca-93710'} />
                    <Redirect exact from={'/clinic/3357/hometown-urgent-care-springfield'} to={'/practice/U4237-hometown-urgent-care-&-occupational-health-springfield-oh-45504'} />
                    <Redirect exact from={'/clinic/4680/marshfield-clinic-lake-hallie-center'} to={'/practice/U4202-marshfield-clinic-walk-in-care-chippewa-falls-wi-54729'} />
                    <Redirect exact from={'/clinic/4926/columbia-st-marys-prospect-medical-commons-express-care'} to={'/practice/U4179-ascension-health-walk-in-care-milwaukee-wi-53211'} />
                    <Redirect exact from={'/clinic/4776/aspirus-family-walk-in'} to={'/practice/U4156-aspirus-walk-in-care-wausau-wi-54401'} />
                    <Redirect exact from={'/clinic/4551/st-anthony-prompt-care'} to={'/practice/U4132-chi-franciscan-urgent-care-gig-harbor-wa-98335'} />
                    <Redirect exact from={'/clinic/4635/evergreenhealth-urgent-care-woodinville'} to={'/practice/U4077-evergreenhealth-urgent-care-woodinville-wa-98072'} />
                    <Redirect exact from={'/clinic/4505/sentara-urgent-care-independence-blvd'} to={'/practice/U3966-sentara-healthcare-walk-in-care-virginia-beach-va-23455'} />
                    <Redirect exact from={'/clinic/4461/sentara-internal-medicine-physicians-walk-in-care'} to={'/practice/U3964-sentara-healthcare-walk-in-care-norfolk-va-23502'} />
                    <Redirect exact from={'/clinic/4515/valley-health-urgent-care-winchester'} to={'/practice/U3949-valley-health-walk-in-care-winchester-va-22601'} />
                    <Redirect exact from={'/clinic/4390/nova-urgent-care-ashburn'} to={'/practice/U3926-inova-health-walk-in-care-ashburn-va-20147'} />
                    <Redirect exact from={'/clinic/3537/urgi-care'} to={'/practice/U3532-irmc-urgicare-blairsville-pa-15717'} />
                    <Redirect exact from={'/clinic/3471/eugene-urgent-care-coburg-rd'} to={'/practice/U3399-nova-health-urgent-care-eugene-or-97401'} />
                    <Redirect exact from={'/clinic/3433/urgent-care-of-green-country-owasso'} to={'/practice/U3312-nextcare-urgent-care-owasso-ok-74055'} />
                    <Redirect exact from={'/clinic/3375/westar-urgent-care'} to={'/practice/U3158-immediate-health-urgent-care-westerville-oh-43082'} />
                    <Redirect exact from={'/clinic/3351/wedgewood-urgent-care'} to={'/practice/U3157-immediate-health-urgent-care-powell-oh-43065'} />
                    <Redirect exact from={'/clinic/2715/saint-marys-urgent-care-galena'} to={'/practice/U3034-prime-healthcare-walk-in-care-reno-nv-89511'} />
                    <Redirect exact from={'/clinic/4093/carexpress-canyon'} to={'/practice/U280-carexpress-canyon-tx-79015'} />
                    <Redirect exact from={'/clinic/2504/the-urgency-room-kansas-city'} to={'/practice/U2593-nextcare-urgent-care-kansas-city-mo-64153'} />
                    <Redirect exact from={'/clinic/2260/covenant-medexpress-urgent-care-saginaw-state-street'} to={'/practice/U2415-covenant-med-express-saginaw-mi-48603'} />
                    <Redirect exact from={'/clinic/2252/docnow-urgent-care-wellpointe'} to={'/practice/U23620-chmc-omaha-walk-in-care-kearney-ne-68845'} />
                    <Redirect exact from={'/clinic/2249/oxford-urgent-care'} to={'/practice/U2356-great-lakes-urgent-care-oxford-mi-48371'} />
                    <Redirect exact from={'/clinic/2188/lakeside-urgent-care'} to={'/practice/U2304-great-lakes-urgent-care-clinton-twp-mi-48038'} />
                    <Redirect exact from={'/clinic/2026/mycare-express-eldersburg'} to={'/practice/U2225-expresscare-urgent-care-eldersburg-md-21784'} />
                    <Redirect exact from={'/clinic/1180/healthcare-clinic-panola-rd'} to={'/practice/U21868-walgreens-healthcare-clinic-lithonia-ga-30038'} />
                    <Redirect exact from={'/clinic/3687/minuteclinic-springfield-baltimore-pike'} to={'/practice/U21388-minute-clinic-springfield-pa-19064'} />
                    <Redirect exact from={'/clinic/3928/the-little-clinic-kroger-gallatin'} to={'/practice/U20494-the-little-clinic-gallatin-tn-37066'} />
                    <Redirect exact from={'/clinic/3293/the-little-clinic-kroger-englewood'} to={'/practice/U20483-the-little-clinic-englewood-oh-45322'} />
                    <Redirect exact from={'/clinic/3259/the-little-clinic-kroger-hyde-park'} to={'/practice/U20455-the-little-clinic-cincinnati-oh-45209'} />
                    <Redirect exact from={'/clinic/3278/the-little-clinic-kroger-graceland-shopping-center'} to={'/practice/U20443-the-little-clinic-columbus-oh-43214'} />
                    <Redirect exact from={'/clinic/3277/the-little-clinic-kroger-georgesville-square'} to={'/practice/U20439-the-little-clinic-columbus-oh-43228'} />
                    <Redirect exact from={'/clinic/3258/the-little-clinic-kroger-harpers-point'} to={'/practice/U20430-the-little-clinic-cincinnati-oh-45249'} />
                    <Redirect exact from={'/clinic/1867/the-little-clinic-kroger-stony-brook'} to={'/practice/U20422-the-little-clinic-louisville-ky-40299'} />
                    <Redirect exact from={'/clinic/1808/the-little-clinic-kroger-fort-mitchell'} to={'/practice/U20397-the-little-clinic-fort-mitchell-ky-41017'} />
                    <Redirect exact from={'/clinic/678/the-little-clinic-king-soopers-colorado-springs'} to={'/practice/U20336-the-little-clinic-colorado-springs-co-80918'} />
                    <Redirect exact from={'/clinic/1522/springfield-clinic-prompt-care-main'} to={'/practice/U20108-springfield-clinic-urgent-care-springfield-il-62703'} />
                    <Redirect exact from={'/clinic/1877/redicare-walk-in-care-center'} to={'/practice/U1987-bon-secours-mercy-health-walk-in-care-paducah-ky-42003'} />
                    <Redirect exact from={'/clinic/57/greater-mobile-urgent-care-west-mobile'} to={'/practice/U1987-bon-secours-mercy-health-walk-in-care-paducah-ky-42003'} />
                    <Redirect exact from={'/clinic/1810/urgent-care-of-kentucky-georgetown'} to={'/practice/U1971-urgent-care-centers-of-kentucky-georgetown-ky-40324'} />
                    <Redirect exact from={'/clinic/2509/the-urgency-room-lees-summit-mo'} to={'/practice/U1916-nextcare-urgent-care-lees-summit-mo-64064'} />
                    <Redirect exact from={'/clinic/1256/access-medical-clinics-mililani-town-center'} to={'/practice/U18993-hawaii-pacific-health-urgent-care-mililani-hi-96789'} />
                    <Redirect exact from={'/clinic/1614/medcheck-east'} to={'/practice/U1833-community-health-medcheck-indianapolis-in-46219'} />
                    <Redirect exact from={'/clinic/1565/medcheck-carmel'} to={'/practice/U1813-community-health-medcheck-carmel-in-46032'} />
                    <Redirect exact from={'/clinic/1613/medcheck-castleton'} to={'/practice/U1812-community-health-medcheck-indianapolis-in-46256'} />
                    <Redirect exact from={'/clinic/1555/batesville-urgent-care'} to={'/practice/U1799-accudoc-urgent-care-batesville-in-47006'} />
                    <Redirect exact from={'/clinic/1644/hammond-clinic-urgent-care'} to={'/practice/U1756-franciscan-expresscare-munster-in-46321'} />
                    <Redirect exact from={'/clinic/448/la-dhs-urgent-care-hubert-h-humphrey'} to={'/practice/U17343-ladhs-urgent-care-los-angeles-ca-90003'} />
                    <Redirect exact from={'/clinic/3251/doctors-express-oakleyhyde-park'} to={'/practice/U17334-carefirst-urgent-care-cincinnati-oh-45209'} />
                    <Redirect exact from={'/clinic/1520/memorial-expresscare-north-dirksen'} to={'/practice/U1731-memorial-expresscare-springfield-il-62702'} />
                    <Redirect exact from={'/clinic/1521/memorial-expresscare-south-sixth-street'} to={'/practice/U17246-memorial-expresscare-springfield-il-62703'} />
                    <Redirect exact from={'/clinic/1305/sherman-immediate-care-algonquin'} to={'/practice/U1700-advocate-aurora-walk-in-care-algonquin-il-60102'} />
                    <Redirect exact from={'/clinic/1518/sherman-immediate-care-south-elgin'} to={'/practice/U1699-advocate-aurora-walk-in-care-south-elgin-il-60177'} />
                    <Redirect exact from={'/clinic/5517/citymd-west-seattle-urgent-care'} to={'/practice/U16954-chi-franciscan-urgent-care-seattle-wa-98116'} />
                    <Redirect exact from={'/clinic/1462/osf-promptcare-fort-jesse'} to={'/practice/U1694-osf-healthcare-walk-in-care-normal-il-61761'} />
                    <Redirect exact from={'/clinic/1519/memorial-expresscare-koke-mill'} to={'/practice/U1677-memorial-expresscare-springfield-il-62704'} />
                    <Redirect exact from={'/clinic/4089/st-joseph-express-bryan'} to={'/practice/U16467-chi-saint-joseph-health-walk-in-care-bryan-tx-77807'} />
                    <Redirect exact from={'/clinic/1342/carle-convenient-care-champaign-on-curtis'} to={'/practice/U1632-carle-convenient-care-champaign-il-61822'} />
                    <Redirect exact from={'/clinic/1310/dreyer-walk-in-care-fox-valley-villages'} to={'/practice/U1607-advocate-aurora-walk-in-care-aurora-il-60504'} />
                    <Redirect exact from={'/clinic/2003/medone-urgent-care'} to={'/practice/U15200-medone-urgent-care-bethesda-md-20814'} />
                    <Redirect exact from={'/clinic/4910/premium-urgent-care'} to={'/practice/U14928-premium-urgent-care-clovis-ca-93611'} />
                    <Redirect exact from={'/clinic/3490/medford-medical-clinic-urgent-care'} to={'/practice/U14754-providence-health-&-services-walk-in-care-medford-or-97501'} />
                    <Redirect exact from={'/clinic/1185/quick-med-zebulon'} to={'/practice/U1453-atrium-health-urgent-care-macon-ga-31210'} />
                    <Redirect exact from={'/clinic/1184/quick-med-riverside-drive'} to={'/practice/U1452-atrium-health-urgent-care-macon-ga-31210'} />
                    <Redirect exact from={'/clinic/5595/st-francis-urgent-care-southbridge'} to={'/practice/U14378-allina-health-walk-in-care-shakopee-mn-55379'} />
                    <Redirect exact from={'/clinic/5509/north-cache-valley-instacare'} to={'/practice/U14149-intermountain-instacare-hyde-park-ut-84318'} />
                    <Redirect exact from={'/clinic/4982/mydoc-urgent-care'} to={'/practice/U13823-mydoc-urgent-care-philadelphia-philadelphia-pa-19102'} />
                    <Redirect exact from={'/clinic/1345/advocate-medical-group-immediate-care-center'} to={'/practice/U13391-advocate-aurora-walk-in-care-chicago-il-60643'} />
                    <Redirect exact from={'/clinic/1662/schneck-convenient-care-of-washington-county'} to={'/practice/U13362-schneck-same-day-care-salem-in-47167'} />
                    <Redirect exact from={'/clinic/5564/excel-urgent-care-of-old-bridge'} to={'/practice/U13268-excel-urgent-care-old-bridge-nj-8857'} />
                    <Redirect exact from={'/clinic/2683/healthcare-partners-wynn'} to={'/practice/U13250-healthcare-partners-urgent-care-las-vegas-nv-89103'} />
                    <Redirect exact from={'/clinic/1670/good-samaritan-convenient-care-clinic'} to={'/practice/U12870-good-samaritan-walk-in-care-vincennes-in-47591'} />
                    <Redirect exact from={'/clinic/5555/greater-mobile-urgent-care-saraland'} to={'/practice/U12770-greater-mobile-urgent-care-saraland-al-36571'} />
                    <Redirect exact from={'/clinic/4892/advanced-urgent-care-24-hr'} to={'/practice/U12530-advanced-urgent-care-illinois-orland-park-il-60462'} />
                    <Redirect exact from={'/clinic/3945/jackson-clinic-convenient-care-north'} to={'/practice/U12371-the-jackson-clinic-convenient-care-jackson-tn-38305'} />
                    <Redirect exact from={'/clinic/5006/central-jersey-urgent-care'} to={'/practice/U11769-central-jersey-urgent-care-ocean-nj-7712'} />
                    <Redirect exact from={'/clinic/3631/premier-urgent-care-newtown'} to={'/practice/U11036-tower-health-walk-in-care-newtown-pa-18940'} />
                    <Redirect from={'/clinic'} to={'/practice'} />

                    <Route component={() => (<NotFound404Page underConstruction={false}/>)} />
                </Switch>


                <Footer />
                <Copyright />
                <Footer2 />
    
            </div>
        );
    }
}

export default App;
