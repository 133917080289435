import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Faq from '../Faq';
import { buildUrlWithQueryParams } from '../../Helpers';
import { handleIframeMessage } from '../../Client';

const GetStartedSemaglutide = () => {
  useEffect(() => {
    window.addEventListener('message', handleIframeMessage);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener('message', handleIframeMessage);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Get Started with Semaglutide</title>
      </Helmet>
      <iframe
        src={buildUrlWithQueryParams(
          'https://portal.weightloss.wellsync.com/urgentcare/get-started/sem'
        )}
        title="Get Started with Semaglutide"
        className="full-page"
      />

      <Faq id="faq" />
    </>
  );
};

export default GetStartedSemaglutide;
