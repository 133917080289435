import React from 'react';
// eslint-disable-next-line no-unused-vars
import {isMobile} from 'react-device-detect';
// eslint-disable-next-line no-unused-vars
import {isZIPValid} from "../../Client";

class ZIPCodeWidgetClaimPage extends React.Component {
    state = {
        zip: this.props.initialZIP ? this.props.initialZIP : "",
        prompt: false,
        query: this.props.initialQuery ? this.props.initialQuery : "",
    }

    handleZIPChange = (e) => {
        this.setState({
            zip: e.target.value,
            prompt: false,
        });
    }

    handleQueryChange = (e) => {
        this.setState({
            query: e.target.value,
        })
    }

    handleZIPSubmitClick = async () => {
        if(this.state.zip === "" && this.state.query === "") {
            this.setState({
                prompt: true,
            });

            return;
        }
        this.props.onQuerySubmit(this.state.zip, this.state.query);
    }

    render() {
        return(
            <div className="zip-box mb-4">
                {
                    this.state.prompt &&
                    <p style={{
                        color: "red",
                        fontSize: "0.9rem",
                    }}>Enter a ZIP code or a Practice Name</p>
                }
                <div className="float-left mr-3">
                    <h5>CLINIC NAME</h5><input type="text" className="form-control mb-3 mb-lg-0 mb-xl-0 react zip-input"
                                               placeholder={this.state.query !== "" ? this.props.zip : ""}
                                               required=""
                                               value={this.state.query}
                                               onChange={this.handleQueryChange}
                />
                </div>
                <div className="float-left mr-3">
                    <h5>ZIP CODE</h5><input type="text" className="form-control mb-3 mb-lg-0 mb-xl-0 react zip-input"
                                            placeholder={this.state.zip !== "" ? this.props.zip : ""}
                                            required=""
                                            value={this.state.zip}
                                            onChange={this.handleZIPChange}
                />
                </div>


                <button className="btn-cta" onClick={this.handleZIPSubmitClick}>Find My Location</button>
            </div>
        )
    }
}

export default ZIPCodeWidgetClaimPage;