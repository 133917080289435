import {combineReducers} from "redux";
import initialState from "./initial_state";

const userlocationReducer = (state={}, action) => {
    switch(action.type) {
        case "UPDATE_USER_LOCATION":
            return {
                lat: action.lat,
                lng: action.lng
            };
        default:
            return state;
    }
}

const ziplocationReducer = (state={}, action) => {
    switch(action.type) {
        case "UPDATE_ZIP_LOCATION":
            return {
                lat: action.lat,
                lng: action.lng
            };
        default:
            return state;
    }
}

const userlocationAddressReducer = (state="", action) => {
    switch(action.type) {
        case "UPDATE_USER_LOCATION_ADDRESS":
            return action.address;
        default:
            return state;
    }
}

const ziplocationAddressReducer = (state="", action) => {
    switch(action.type) {
        case "UPDATE_ZIP_LOCATION_ADDRESS":
            return action.address;
        default:
            return state;
    }
}

const zipReducer = (state="", action) => {
    if(action.type === "UPDATE_ZIP") {
        return action.zip;
    }
    else {
        return state;
    }
}

const filterReducer = (state={}, action) => {
    if(action.type === "UPDATE_FILTER_SETTINGS") {
        return action.settings;
    }
    else {
        return state;
    }
}

const clinicsReducer = (state=[], action) => {
    if(action.type === "UPDATE_CLINICS") {
        return action.clinics;
    }
    else {
        return state;
    }
}

const filteredClinicsReducer = (state=[], action) => {
    if(action.type === "UPDATE_FILTERED_CLINICS") {
        return action.clinics;
    }
    else {
        return state;
    }
}

const zipBasedReducer = (state=false, action) => {
    if(action.type === "UPDATE_SEARCH_INDICATOR") {
        return action.zipBased;
    }
    else {
        return state;
    }
}

const redirectedFromEmptyZIPBasedSearchReducer = (state=false, action) => {
    if(action.type === "UPDATE_REDIRECT_FROM_EMPTY_ZIP_SEARCH") {
        return action.status;
    }
    else {
        return state;
    }
}

const redirectedFromEmptyLocationBasedSearchReducer = (state=false, action) => {
    if(action.type === "UPDATE_REDIRECT_FROM_EMPTY_LOCATION_SEARCH") {
        return action.status;
    }
    else {
        return state;
    }
}

const displayAddressReducer = (state="Invalid ZIP or location", action) => {
    if(action.type === "UPDATE_DISPLAY_ADDRESS") {
        return action.address;
    }
    else {
        return state;
    }
}

const resultsPromptReducer = (state="", action) => {
    if(action.type === "UPDATE_RESULTS_PROMPT") {
        return action.message;
    }
    else {
        return state;
    }
}

const loadingReducer = (state=false, action) => {
    if(action.type === "UPDATE_LOADING_INDICATOR") {
        return action.status;
    }
    else {
        return state;
    }
}

const combinedReducers = combineReducers({
    userLocation: userlocationReducer,
    userLocationAddress: userlocationAddressReducer,
    zipLocation: ziplocationReducer,
    zipLocationAddress: ziplocationAddressReducer,
    zip: zipReducer,
    filter: filterReducer,
    clinics: clinicsReducer,
    filteredClinics: filteredClinicsReducer,

    zipBased: zipBasedReducer,
    redirectedFromEmptyZIPBasedSearch: redirectedFromEmptyZIPBasedSearchReducer,
    redirectedFromEmptyLocationBasedSearch: redirectedFromEmptyLocationBasedSearchReducer,
    displayAddress: displayAddressReducer,
    resultsPrompt: resultsPromptReducer,

    loading: loadingReducer,
});

const reducer = (state, action) => {
    if(action.type === "CLEAR_STORE") {
        // console.log("Clearing store...");
        return initialState;
    }

    // console.log("Proceeding to reducers...");
    return combinedReducers(state, action);
}

export default reducer;