import React, {useEffect, useState} from 'react';
import {Redirect} from "react-router-dom";
import ListingPage from "../../listings/ListingPage";
import ResultsPage from "../../results/ResultsPage";
import {getPracticeSet, getPracticeV2} from "../../../Client";

const PreviewMarketingCampaignPage = (props) => {
    const [redirectTo404, setRedirectTo404] = useState(false);
    const [campaignPage, setCampaignPage] = useState(null);

    const campaign_parameters = {};
    props.location.search.replace('?', '').split('&').forEach((p) => {
        const pairs = p.split('=');
        campaign_parameters[pairs[0]] = pairs[1];
    });
    if(campaign_parameters.locations) {
        campaign_parameters['locations'] = campaign_parameters['locations'].split(',');
    }

    // eslint-disable-next-line no-unused-vars
    const [campaignParameters, setCampaignParameters] = useState(campaign_parameters);

    useEffect(() => {
        async function loadLocations () {
            if(!campaignParameters.locations) {
                setRedirectTo404(true);
                return;
            }

            switch(campaignParameters.type) {
                case 'INDIVIDUAL_PLAN':
                    console.log('INDIVIDUAL_PLAN');
                    const practice = await getPracticeV2(campaignParameters.locations[0]);
                    if(practice.error) {
                        console.log('Error in fetching practice.');
                        setRedirectTo404(true);
                        break;
                    }
                    setCampaignPage(<ListingPage preview={true} campaignParameters={campaignParameters} practice={practice}/>);
                    break;
                case 'ORGANIZATION_PLAN':
                    console.log('ORGANIZATION_PLAN');
                    const practices = await getPracticeSet(campaignParameters.locations);
                    if(practices.error) {
                        console.log('Error in fetching practices.');
                        setRedirectTo404(true);
                        break;
                    }
                    setCampaignPage(<ResultsPage preview={true} campaignParameters={campaignParameters} practices={practices} />);
                    break;
                case 'GEOLOCATION_PLAN':
                    console.log('GEOLOCATON_PLAN');
                    const practices_from_geolocation = await getPracticeSet(campaignParameters.locations);
                    if(practices_from_geolocation.error) {
                        console.log('Error in fetching practices.');
                        setRedirectTo404(true);
                        break;
                    }
                    setCampaignPage(<ResultsPage preview={true} campaignParameters={campaignParameters} practices={practices_from_geolocation} />);
                    break;
                default:
                    console.log('NO PLAN');
                    setRedirectTo404(true);
                    break;
            }
        }

        loadLocations();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {
                (!campaignParameters.locations || !campaign_parameters.type || redirectTo404) && <Redirect to='/404' />
            }
            {campaignPage}
        </>
    )
}

export default PreviewMarketingCampaignPage;