import React, {useEffect, useState} from 'react';
import ListingPage from "../listings/ListingPage";
import {getCampaign} from "../../Client";
// eslint-disable-next-line no-unused-vars
import LoadMoreButton from "../results/LoadMoreButton";
import {Redirect} from "react-router-dom";
import ResultsPage from "../results/ResultsPage";

const MarketingCampaignPage = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [campaign, setCampaign] = useState(null);
    const [campaignPage, setCampaignPage] = useState(null);
    const [redirectTo404, setRedirectTo404] = useState(false);
    const code = props.match.params.slug;

    useEffect(() => {
        async function loadCampaign() {
            setIsLoading(true);
            const getCampaignResults = await getCampaign(code);
            console.log(getCampaignResults);
            if (getCampaignResults.error) {
                setIsLoading(false);
                setRedirectTo404(true);
                return;
            }
            setCampaign(getCampaignResults);
            setIsLoading(false);
        }

        loadCampaign();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if(campaign) {
            switch(campaign.campaign.code) {
                case 'INDIVIDUAL_PLAN':
                    setCampaignPage(<ListingPage campaign={campaign} />);
                    break;
                case 'ORGANIZATION_PLAN':
                    setCampaignPage(<ResultsPage key={Date.now()} fromCampaign={true} campaign={campaign}/>);
                    break;
                default:
                    break;
            }
        }
    }, [campaign]);

    return (
        <>
            {
                redirectTo404 && <Redirect to='/404' />
            }
            {
                isLoading ? <div className='loading-overlay'>
                    <div className='message'>
                        <i className="fa fa-spinner loading-icon" aria-hidden="true"></i>
                        <p>Loading...</p>
                    </div>
                </div> : campaignPage
            }
        </>
    )
}

export default MarketingCampaignPage;