import React from 'react';
// eslint-disable-next-line no-unused-vars
import {getAddressUsingCoordinates} from "../../Client";

const CoordinateAddressWidget = ({address}) => {
    return (
        <h5 className="b18 my-4">{address}</h5>
    );
}

export default CoordinateAddressWidget;