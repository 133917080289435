import React, { useState } from 'react';


const faqs = [
{
	category: "About",
	question: "What does asynchronous care involve?",
	answer: "Asynchronous care means you can receive medical attention without real-time interaction. You'll fill out a clinician-reviewed assessment form at your convenience, which a clinician will then review to decide on your treatment plan.",
},
{
	category: "About",
	question: "Is my personal information secure?",
	answer: "We prioritize your privacy and data security. Our platform complies with all relevant privacy laws and employs stringent security protocols to protect your information. For details, please see our Privacy Policy.",
},
{
	category: "About",
	question: "Is UrgentCare available where I live?",
	answer: "UrgentCare services are accessible in most states, but some states have regulations that restrict clinicians from prescribing without a live consultation or lab results. Start the assessment process and enter your state to check availability.",
},
{
	category: "About",
	question: "How can I reach out for support?",
	answer: "For support, please email us at support@UrgentCare.com",
},
{
	category: "My Account",
	question: "How do I update my billing details?",
	answer: "Your payment information is handled by Stripe. You'll get multiple emails after your initial and recurring payments with links to manage your payment methods directly on Stripe's site.",
},
{
	category: "My Account",
	question: "When will my subscription be charged?",
	answer: "Charges occur before your initial service and then monthly upon renewal. If your payment doesn't go through or if you're not approved, we'll initiate a refund, which may take up to 7 days to reflect on your account.",
},
{
	category: "My Account",
	question: "Do you accept insurance for this program?",
	answer: "Currently, we do not process insurance for the medical weight loss program.",
},
{
	category: "My Account",
	question: "How can I end my subscription?",
	answer: "After approval, you'll receive an email with a payment receipt containing a link to \"Manage/Cancel Your Subscription.\" Clicking this takes you to Stripe, where you can cancel your subscription directly.",
},

{
	category: "Prescriptions and Refills",
	question: "How does the monthly refill process work?",
	answer: "Your medication refills will automatically process monthly until cancellation. Payment is taken around the same time you first completed your questionnaire, alongside a mandatory renewal form. Medication won't ship until this form is reviewed, even if payment has been made.",
},

{
	category: "Prescriptions and Refills",
	question: "How is my prescription sent to the pharmacy?",
	answer: "Once your medical provider reviews and approves your prescription, it's sent electronically to the pharmacy for fulfillment.",
},
{
	category: "Prescriptions and Refills",
	question: "How will I get my medication?",
	answer: "Your prescribed medication will be shipped directly to the address you provided during checkout after the pharmacy prepares it.",
},
{
	category: "Prescriptions and Refills",
	question: "What if the provider needs more information after my assessment?",
	answer: "If your provider requires more details, they'll contact you via the contact information you've provided.",
},
{
	category: "Prescriptions and Refills",
	question: "Who prescribes the medications?",
	answer: "GLP-1 prescriptions for weight loss are written by licensed medical professionals, including doctors and nurse practitioners, focused on personalized and safe medication management.",
},
{
	category: "Prescriptions and Refills",
	question: "Can I switch my current GLP-1 medication?",
	answer: "To switch medications, you would need to cancel your current subscription and complete a new assessment, choosing the desired medication.",
},


{
	category: "Prescriptions and Refills",
	question: "Is obtaining a GLP-1 prescription online difficult?",
	answer: "With UrgentCare.com, obtaining a GLP-1 prescription are straightforward. Begin by filling out a health questionnaire, where you can select your preferred GLP-1 medication.",
},

{
	category: "Temperature and Potency",
	question: "How should I store my medication once it arrives?",
	answer: "Please refrigerate your medication upon arrival to maintain its effectiveness, though it's designed to handle short-term temperature variations during shipping.",
},
{
	category: "Temperature and Potency",
	question: "Will my medication lose effectiveness if it gets warm during delivery?",
	answer: "Our pharmacy partners test to ensure medication stability at high temperatures during shipping. While it remains potent, it's best to refrigerate upon receipt for long-term storage.",
},

{
	category: "Temperature and Potency",
	question: "What if my medication feels warm when I receive it?",
	answer: "No need to worry if your medication arrives warm. It's been validated to stay effective during transit. Just refrigerate it as soon as you can.",
},
{
	category: "Temperature and Potency",
	question: "How can I be sure my medication's effectiveness after shipping?",
	answer: "All medications are rigorously tested by our pharmacy partners for stability during shipping, ensuring they remain effective when stored as advised.",
},
{
	category: "Medical Weight Loss",
	question: "What is the process for starting medical weight loss?",
	answer: "Begin by visiting our medical weight loss page, make a payment via Stripe, then fill out our intake form with your medical history, weight loss goals, and ID. A provider will review this for a decision on your treatment, with potential follow-up for further discussion.",
},
{
	category: "Medical Weight Loss",
	question: "What disqualifies someone during the intake?",
	answer: "Disqualification can happen if certain health criteria like BMI or specific health conditions are not met. It's also based on clinical assessment for your safety and suitability for the program.",
},
{
	category: "Medical Weight Loss",
	question: "What happens if I'm not approved for a GLP-1 treatment?",
	answer: "Should you not qualify for a GLP-1 treatment, we'll promptly process your refund. Contact support if you encounter any issues with this.",
},

{
	category: "GLP-1s",
	question: "What are GLP-1 medications used for?",
	answer: "GLP-1 medications are utilized for managing obesity and type-2 diabetes by mimicking the GLp-1 hormone which helps in appetite control and blood surgar regulation.",
},
{
	category: "GLP-1s",
	question: "How do GLP-1 aid in weight management?",
	answer: "GLP-1 medications increase feelings of fullness, reduce hunger, and help in blood sugar control, which collectively support weight loss by reducing caloric intake.",
},
{
	category: "GLP-1s",
	question: "Why are GLP-1 medications becoming popular for weight loss?",
	answer: "Their popularity stems from their effectiveness in significant weight loss and blood sugar management, meeting the growing demand for effective obesity treatments.",
},
{
	category: "GLP-1s",
	question: "What can I expect from using GLP-1 for weight loss?",
	answer: "Expect noticeable weight reduction, diminished appetite, improved glycemic control, and potentially other health benefits like reduced cardiovascular risks.",
},
{
	category: "GLP-1s",
	question: "Are there side effects to be aware of with GLP-1 medications?",
	answer: "Yes, side effects might include nausea, vomiting, or injection site reactions. There's also a risk, though rare, of pancreatitis. They're not recommended for those with a history of certain thyroid cancers. Review the Important Safety Information for more details.",
},
{
	category: "GLP-1s",
	question: "How do I determine if a GLP-1 medication is right for me?",
	answer: "A consultation with a medical provider will help decide if GLP-1 medication fits your weight loss journey based on your health profile and goals.",
},
{
	category: "GLP-1s",
	question: "Will I need nutritional supplements while on GLP-1?",
	answer: "You might eat less, so ensuring adequate nutrition with supplements like protein, vitamins, fiber, and probiotics could be beneficial for overall health during your weight loss.",
},
{
	category: "GLP-1s",
	question: "Which GLP-1 medications can I get for weight loss?",
	answer: "Compounded Semaglutide and Tirzepatide vials with syringes for subcutaneous injection.",
},
{
	category: "GLP-1s",
	question: "What's meant by a compound medication?",
	answer: "Compounded medications are custom-made by licensed facilities per a prescription, using FDA-licensed ingredients. They're tailored but not FDA-approved like commercial drugs since they're prepared for individual use.",
},
{
	category: "GLP-1s",
	question: "How does semaglutide or tirzepatide promote weight loss and why is it effective?",
	answer: "Semaglutide and Tirzepatide mimic GLP-1 to regulate appetite and improve insulin response, leading to weight loss by reducing hunger and enhancing satiety, with significant effectiveness in both diabetic and non-diabetic individuals.",
},
{
	category: "GLP-1s",
	question: "What are the safety concerns with semaglutide or tirzepatide for weight loss?",
	answer: "Key concerns include gastrointestinal side effects, potential for pancreatitis, and nutritional deficiencies if diet isn't managed. Always discuss risks with your provider.",
},
{
	category: "GLP-1s",
	question: "How does semaglutide or tirzepatide contribute to long-term health beyond weight loss?",
	answer: "Beyond weight management, semaglutide or tirzepatide improves metabolic health by bettering blood sugar levels, potentially reducing cardiovascular risk factors, and supporting long-term weight management through sustained appetite control.",
},
{
	category: "GLP-1s",
	question: "Who qualifies for semaglutide or tirzepatide for weight loss?",
	answer: "Adults with obesity or those overweight with related health issues might qualify, with eligibility determined by BMI and health assessment by a healthcare provider.",
},
{
	category: "GLP-1s",
	question: "Is a healthy lifestyle crucial while on semaglutide or tirzepatide?",
	answer: "Absolutely, combining semaglutide or tirzepatide with a healthy diet and regular exercise optimizes weight loss and health benefits, enhancing the drug's effectiveness.",
},
{
	category: "GLP-1s",
	question: "How does Tirzepatide aid in long-term weight management?",
	answer: "Tirzepatide supports sustained weight loss by acting on GLP-1 and GIP receptors, reducing appetite significantly. Studies like SURMOUNT-4 show continued weight reduction with ongoing treatment, highlighting the need for sustained therapy for weight management.",
},
{
	category: "GLP-1s",
	question: "What are the side effects of Tirzepatide?",
	answer: "Side effects might include nausea, vomiting, diarrhea, and more. Managing them might involve starting with a lower dose and adjusting as needed, with close consultation with your healthcare provider.",
},
{
	category: "GLP-1s",
	question: "How does Tirzepatide work for weight loss and blood sugar control?",
	answer: "By acting as both a GLP-1 and GIP receptor agonist, Tirzepatide optimizes insulin secretion, reduces appetite, and delays gastric emptying, aiding in weight loss and glycemic control.",
},
{
	category: "GLP-1s",
	question: "Can Tirzepatide significantly affect appetite?",
	answer: "Yes, tirzepatide has shown to a reduce appetite which contributes to effective weight loss in clinical studies.",
},
{
	category: "GLP-1s",
	question: "What should I know before starting Tirzepatide for weight loss?",
	answer: "Understand it's administered weekly, potential side effects, and the importance of gradually increasing dosage to manage digestive issues. It's approved for chronic weight management alongside diet and exercise.",
},
{
	category: "GLP-1s",
	question: "How does Tirzepatide stimulate insulin secretion uniquely?",
	answer: "Tirzepatide activates both GLP-1 and GIP receptors, enhancing insulin secretion more effectively than treatments targeting only one receptor, aiding in better blood sugar regulation and weight loss.",
},
{
	category: "GLP-1s",
	question: "What is GIP, and how does it differ from GLP-1s?",
	answer: "GIP (Glucose-dependent Insulinotropic Polypeptide) aids in insulin response post-meal, primarily focusing on glucose and fat absorption. GLP-1 not only helps with insulin but also reduces appetite, slows digestion, and offers neuroprotective benefits, making it broader in scope for treating diabetes and supporting weight loss.",
},
{
	category: "GLP-1s",
	question: "How does Tirzepatide’s dual action contribute to its effectiveness?",
	answer: "By targeting both GLP-1 and GIP, Tirzepatide manages blood sugar and supports weight loss more comprehensively than single-receptor agonists, providing a dual benefit for diabetes and obesity management.",
},
];

function categorizeFaqs(faqs) {
  const categories = {};
  faqs.forEach(faq => {
	if (!categories[faq.category]) {
	  categories[faq.category] = [];
	}
	categories[faq.category].push(faq);
  });

  return Object.keys(categories).map(category => ({
	name: category,
	questions: categories[category]
  }));
}

function Faq() {
  const categorizedFaqs = categorizeFaqs(faqs);

  return (
	<div id="faq" className="weightloss-faq">
	  <hr className="divider"/>
	  <h3 className="title">Frequently Asked Questions</h3>
	  
	  {categorizedFaqs.map((category, idx) => (
		<DisclosureItem key={idx} faq={{question: category.name, answer: category.questions}} isCategory={true} />
	  ))}
	</div>
  );
}

function DisclosureItem({ faq, isCategory = false }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
	<div className={`qa ${isCategory ? 'category' : 'alt'}`}>
	  <dt className="question">
		<button 
		  className="title"
		  onClick={() => setIsOpen(!isOpen)}
		>
		  <span className="icon">
			{isOpen ? '-' : '+'}
		  </span>
		  <span>{faq.question}</span>
		</button>
	  </dt>
	  {isOpen && (
		<dd className="answer">
		  {isCategory ? 
			faq.answer.map((innerFaq, idx) => (
			  <DisclosureItem key={idx} faq={innerFaq} isCategory={false} />
			)) :
			faq.answer
		  }
		</dd>
	  )}
	</div>
  );
}

export default Faq;