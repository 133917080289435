import React from 'react';

class ResultsFilterSection extends React.Component {
    state = {
        radius: 20,
        covidTest: false,
        antibodyTest: false,
        xray: false,
    }

    handleRadiusChange = (event) => {
        this.setState({
            radius: event.target.value,
        });
    }

    handleMouseEvent = (event) => {
        if (event.type === "mouseup" || event.type === "touchend") {
            this.setState({
                radius: event.target.value,
            });
            this.props.onFilterChange(event, true);
        }
    }


    render() {
        return (
            <div className="result-info">
                <div className="row">
                    <div className="col-2 col-md-2">
                        Filter:
                    </div>
                    <div className="col-10 col-md-10">
                        <form>
                            {
                                (!this.props.fromCampaign && !this.props.fromPreview) &&
                                <div className="form-group row align-items-center">
                                    <div className="col-6 col-sm-3 col-md-4 col-lg-3">
                                        <label htmlFor="formControlRange">Radius: {this.state.radius} mi</label>
                                    </div>
                                    <div className="col-6 col-sm-8 col-md-3 col-lg-6 px-0">
                                        <div className="slidecontainer">
                                            <input type="range" min="1" max="100" className="slider"
                                                   name='radius'
                                                   defaultValue={this.state.radius}
                                                   onChange={this.handleRadiusChange}
                                                   onMouseDown={this.handleMouseEvent}
                                                   onMouseUp={this.handleMouseEvent}
                                                   onTouchStart={this.handleMouseEvent}
                                                   onTouchEnd={this.handleMouseEvent}
                                                   id="myRange" />
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className="custom-checkbox">
                                <label className="container">COVID-19 Testing
                                    <input type="checkbox" name='covidTest'
                                           onChange={this.props.onFilterChange}
                                           checked={this.props.initialSettings.covidTest}/>
                                        <span className="checkmark"></span>
                                </label>
                            </div>
                            <div className="custom-checkbox">
                                <label className="container">Offers X-Rays
                                    <input type="checkbox" name='xray'
                                           onChange={this.props.onFilterChange}
                                           checked={this.props.initialSettings.xray}/>
                                        <span className="checkmark"></span>
                                </label>
                            </div>
                            <div className="custom-checkbox">
                                <label className="container">Antibody Testing
                                    <input type="checkbox" name='antibodyTest'
                                           onChange={this.props.onFilterChange}
                                           checked={this.props.initialSettings.antibodyTest}/>
                                        <span className="checkmark"></span>
                                </label>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

export default ResultsFilterSection;