import React from 'react';
import {DAYS, getTimeDecimalEquivalent} from "../../Helpers";
import ScheduleRow from "./ScheduleRow";

class ScheduleTable extends React.Component {
    render() {

        const schedules = this.props.schedules;

        console.log(schedules.length);
        // const expectedDays = ['Sunday', 'Saturday', 'Friday', 'Thursday', 'Wednesday', 'Tuesday', 'Monday'];
        // const currentDays = schedules.map((schedule) => {
        //     return schedule.occurrence.name;
        // });
        // console.log(currentDays);

        // const completeSchedule = [];

        // for (var i = 0; i<expectedDays.length; i++){
        //     if (expectedDays[i] !== currentDays[i]) {
        //         return console.log(expectedDays[i]);
        //     }
        //     return console.log(expectedDays[i]);
        // }


        const scheduleRows = schedules.map((schedule) => {
            const practice_opening = getTimeDecimalEquivalent(schedule.opening);
            const practice_closing = getTimeDecimalEquivalent(schedule.closing);


            const deltaUTCOffset = ((((this.props.userUTCOffset + this.props.userDSTUTCOffset)
                - (this.props.practiceUTCOffset + this.props.DSTUTCOffset))) / 60) / 60;

            const user_opening = practice_opening + deltaUTCOffset;
            const user_closing = practice_closing + deltaUTCOffset;

            // return rows here
            return <ScheduleRow key={schedule.occurrence.name} day={schedule.occurrence.name} opening={user_opening} closing={user_closing} />
        });

        const orderedScheduledRows = [];
        for (let day of DAYS) {
            const practice_schedule = scheduleRows.find((s) => s.props.day === day);
            if (!practice_schedule) {
                orderedScheduledRows.push(<ScheduleRow key={day} day={day} opening='(Closed)' closing='(Closed)'/>);
            }
            for (let row of scheduleRows) {
                if (day === row.props.day) {
                    orderedScheduledRows.push(row);
                }
            }
        }


        return (
            <table className="table d-block hours mb-4 table-responsive">
                <thead>
                <tr>
                    <th scope="col"><i className="fas fa-clock mr-2"></i>Hours</th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                </tr>
                </thead>
                <tbody>
                {orderedScheduledRows.length > 0 ? orderedScheduledRows : <div className="call-for-more-information" style={{
                    color: "gray",
                    textTransform: "none",
                    padding: "0px 20px",
                }}>Please, call for more information.</div>}
                </tbody>
            </table>

        )
    }
}

export default ScheduleTable;