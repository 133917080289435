import React from 'react';
import {DAYS, get12HourFormatTimeString, isStillOpenV2} from "../../Helpers";

const ScheduleRow = ({day, opening, closing}) => {

    if(isScheduleToday(day)) {
        const status = isStillOpenV2(opening, closing);
        return (
            <tr className="today">
                <th scope="row">{day}</th>
                { opening === '(Closed)' && closing === '(Closed)' ?
                    <td>(Closed)</td>
                    :
                    <td>{get12HourFormatTimeString(opening)} - {get12HourFormatTimeString(closing)}</td>
                }
                <td>{`${status.status}${status.message !== "" ? " - " + status.message : ""}`}</td>
            </tr>
        )
    }


    return (
        <tr>
            <th scope="row">{day}</th>
            {opening === '(Closed)' && closing === '(Closed)' ?
                <td>(Closed)</td>
                :
                <td colSpan="2">{get12HourFormatTimeString(opening)} - {get12HourFormatTimeString(closing)}</td>
            }
        </tr>
    )
}

const isScheduleToday = (day) => {
    const now = new Date();
    return day === DAYS[now.getDay()];
}

export default ScheduleRow;