import React from 'react';

const ServicesOffered = ({title, services}) => {
    const services_list = services.replaceAll('-', ',').split(',').map((service) => service.trim());
    console.log(services_list);
    return (
        <div className="col-md-6 mt-5 mb-0 mt-5 xy-md-5">
            <div className="list-box round-border-10 h-100 p-4 p-md-5">
                <img src="/img/ico/services-we-offer.svg" alt="..." class="mb-4 mb-md-5 img-head"/>
                <h3 className="mb-4">{title}</h3>
                {
                    services_list.length < 1 ?
                 <p className="no-services"><br/><br/><br/><br/>Please call for more information.</p>
                    :
                     <p className="mb-4 pl-4">
                         <ul>
                            {
                                services_list.map(service =><li>{service}</li>)
                            }
                         </ul>
                     </p>
                }
            </div>
        </div>
    )
}

export default ServicesOffered;